import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'

class marketoMedia extends React.Component {
  
    render() {
      return (
        <section className="bg-gray-light">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div className="RTP_RCMD2" data-rtp-template-id="template1" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      )
    }
  }
  
  export default marketoMedia        