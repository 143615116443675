import React, { Component, Fragment } from "react"
import WhatsNew from './whatsNew'
import MarketoMedia from './marketoMedia'
import {targetingEnabled} from '../utils/helpers.js'

class RecommendedContent extends Component {

  handleCookies = (fallback) => { 

    if (targetingEnabled()) {
      return <MarketoMedia />
    } else {
      if (fallback) {
        return <WhatsNew />
      } else {
        return
      }      
    }
 }
  render() {
    return (
      <Fragment key="1"> 
        {this.handleCookies(this.props.fallback)} 
      </Fragment>
    )
  }
}
export default RecommendedContent