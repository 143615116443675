import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import { MDBRow, MDBCol } from 'mdbreact'
import { MDBAnimation } from 'mdbreact'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class ColumnOverlayLogos extends React.Component {
  render() {
    return (
      <>
        <MDBRow>
          <MDBCol lg="12">
            <MDBAnimation reveal type="fadeInUp">
              <div className="features-absolute rounded bg-white">
                <MDBRow>
                  <MDBCol lg="12" md="12" className="my-4">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="d-flex p-4">
                          <div className="flex-1">
                            <div
                              className="font-alt font-w-200 text-center title-xs-small title-large mb-xl-2 mx-3"
                              dangerouslySetInnerHTML={{
                                __html: this.props.logobox.logoboxtitle,
                              }}
                            />
                          </div>
                        </div>
                      </MDBCol>

                      <MDBCol md="12">
                        <div className="d-flex">
                          <div className="flex-1">
                            <h2
                              className="font-w-700 text-center font-alt mb-4 title-xs-large mx-3 letter-spacing-1"
                              dangerouslySetInnerHTML={{
                                __html: this.props.logobox.logoboxsubtitle,
                              }}
                            />
                          </div>
                        </div>
                      </MDBCol>

                      {this.props.logobox.logo.map((logos, index) => {
                        return (
                          <Fragment key={index}>
                            <MDBCol lg="3" md="6" className="text-center mt-3">
                              <div className="d-flex p-4">
                                <div className="flex-1">
                                 <Link to={logos.logoboxlink} className="text-medium-small">
                                  <Img
                                    fluid={ logos.logoboximage.childImageSharp.fluid }
                                    className="img-fluid mt-2"
                                    alt={logos.logoboxalttext}
                                  />
                                  </Link>
                                </div>
                              </div>
                            </MDBCol>
                          </Fragment>
                        )
                      })}
                    </MDBRow>
                    <div className="btn-toolbar justify-content-center mt-5">
                      {this.props.logobox.logobutton.map((logobuttons, index) => {
                        return (
                          <Fragment key={index}>
                            <Link
                              to={'/' + logobuttons.logoboxbtnlink + '/'}
                              className="nav-link btn btn-mdb-color"
                            >
                              <FontAwesomeIcon
                                icon={faChevronRight}
                                className="mr-2"
                              />
                              {logobuttons.logoboxbtntxt}
                          </Link>
                         </Fragment>
                        )
                      })}
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBAnimation>
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

export default ColumnOverlayLogos
