import React from 'react'
import { MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

class Card extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
      <MDBCol
        lg={this.props.collg}
        md={this.props.colmd}
        className="pb-4 d-flex"
      >
        <MDBCard className="flex-fill">
          {this.props.imageUrl ? (
            this.props.link ?
              (this.props.link.substring(0, 4) !== 'http' && this.props.link.substring(0, 8) !== '/static/') ? 
                <Link to={this.props.link}>
                  <Img
                    top
                    fluid={this.props.imageUrl}
                    overlay="white-slight"
                    hover
                    waves
                    alt={this.props.imageAltText}
                    className="rounded-top drop-shadow"
                  />
                </Link>
              : 
                <a href={this.props.link} rel="noopener" target="_blank">
                  <Img
                    top
                    fluid={this.props.imageUrl}
                    overlay="white-slight"
                    hover
                    waves
                    alt={this.props.imageAltText}
                    className="rounded-top drop-shadow"
                  />
                </a>
            : 
              <Img
                top
                fluid={this.props.imageUrl}
                overlay="white-slight"
                hover
                waves
                alt={this.props.imageAltText}
                className="rounded-top drop-shadow"
              />
          ) : null }

          <MDBCardBody>
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3 className={`font-alt font-w-700 mt-2 letter-spacing-1 ` + (this.props.titleclass ? this.props.titleclass : `title-small title-xs-medium`)}>
                {this.props.title}
              </h3>
              {this.props.subtitle != '' && this.props.subtitle != null && (
                <>
                <div className={this.props.subclass ? "font-w-400 text-xs-medium text-medium-small mt-2" : "font-w-400 text-xs-medium text-medium mt-2"} 
                    dangerouslySetInnerHTML={{__html: this.props.subtitle}} />
                </>
              )}
            </div>
            <hr />
            {this.props.link ? (
              <MDBCardText className={`font-w-400 mt-2 mb-2 ` + this.props.descriptionClass ? this.props.descriptionClass : 'text-medium'}>
                {(this.props.link.substring(0, 4) != 'http' && this.props.link.substring(0, 8) != '/static/') ? (
                  <Link to={this.props.link} className="effect">
                    {this.props.description}
                  </Link>
                ) : (
                  <a
                    href={this.props.link}
                    className="effect"
                    target="_blank"
                    rel="noopener"
                  >
                    {this.props.description}
                  </a>
                )}
              </MDBCardText>
            ) : (
              <MDBCardText className={`font-w-400 mt-2 mb-2 ` + this.props.descriptionClass ? this.props.descriptionClass : 'text-medium'}>{this.props.description}</MDBCardText>
            )}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default Card