import React from 'react'
import { MDBContainer, MDBAnimation } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

class ctaFeature extends React.Component {
  render() {
    return (
      <section className="cta-feature">
        <Img
          fixed={this.props.bgimageUrl}
          className="position-absolute img-bg"
          alt={this.props.bgimageAltText}
        />
        <MDBContainer>
          <div className="cta-feature-inner">
            <div className="media">
            <MDBAnimation reveal type="bounce" count={2} delay="1s">
              <Img
                fixed={this.props.imageUrl}
                className="mr-5"
                alt={this.props.imageAltText}
              />
              </MDBAnimation>
              <div className="media-body">
                <h3 className="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium mt-4 mt-lg-0">
                  {this.props.ctafeaturetitle}
                </h3>
                <h4 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium">
                  {this.props.ctafeaturesubtitle}
                </h4>
              </div>
            </div>

            {this.props.ctafeaturelink ? (
              this.props.ctafeaturelink.charAt(0) == '/' ? (
                <Link
                  to={this.props.ctafeaturelink}
                  className="nav-link btn btn-mdb-color btn-sm-block mt-4 mt-lg-0"
                >
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  {this.props.ctafeaturelinktext}
                </Link>
              ) : (
                <a
                  href={this.props.ctafeaturelink}
                  className="nav-link btn btn-mdb-color btn-sm-block mt-4 mt-lg-0"
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  {this.props.ctafeaturelinktext}
                </a>
              )
            ) : null}
          </div>
        </MDBContainer>
      </section>
    )
  }
}

export default ctaFeature
